
.Contact{
    margin-top: calc(var(--gap) * .25);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 60vw; /*80%;*/
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    color: white;
  }
  
  .Contact-product{
    font-size: calc(var(--text-size));
    line-height: calc(var(--text-size) * 1.75);
    margin-top: calc(6px + 0.5vw);
    /*padding: calc(var(--text-size) * .25);*/
    margin-bottom: auto;
  }
  
  .Contact-product.selected{
    color: white;
  }
  
  .Contact-product.empty{
    color: var(--gray-text);
  }
  
  .Contact-misssed{
    color: var(--red);
    font-size: var(--text-size-small);
  }
  
  .Contact-message{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width:100%;
    font-size: calc(var(--text-size));
    border-radius: calc(var(--border-radius) / 3);
    padding: var(--text-padding);
    line-height: calc(var(--text-size));
  }
  
  .Contact-message.sent{
    background-color: var(--green);
  }
  
  .Contact-message.error{
    background-color: var(--red);
  }

@media (max-width: 775px) {
    .Contact{
        width: 80vw; /*80%;*/
        margin-top: calc(var(--gap) * 1);
    }

    .Contact-product{
      line-height: calc(var(--text-size) * 2.25);
      margin-top: calc(6px + 0.5vw);
      /*padding: calc(var(--text-size) * .25);*/
      margin-bottom: auto;
    }

    @media only screen and (orientation: landscape) {
      .Contact{
        margin-top: calc(var(--gap) * 1);
      }
    }
}
