  /*
  @import url("https://p.typekit.net/p.css?s=1&k=wjj7qyk&ht=tk&f=15671.15672&a=1762211&app=typekit&e=css");
  
  @font-face {
  font-family:"trajan-sans-pro";
  src:url("https://use.typekit.net/af/d12cb1/00000000000000000001319e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/d12cb1/00000000000000000001319e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/d12cb1/00000000000000000001319e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }
  
  @font-face {
  font-family:"trajan-sans-pro";
  src:url("https://use.typekit.net/af/608dc8/00000000000000000001319b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/608dc8/00000000000000000001319b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/608dc8/00000000000000000001319b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }
  
  .tk-trajan-sans-pro { font-family: "trajan-sans-pro",sans-serif; }
  */

  @font-face {
    font-family: "trajan-sans-pro";   /*Can be any text*/
    src: local("trajansanspro-bold-webfont"),
      url("../../fonts/trajansanspro-bold-webfont.woff");
  }


  @font-face {
    font-family: "RobotoBold";   /*Can be any text*/
    src: local("Roboto-Bold"),
      url("../../fonts/Roboto-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "RobotoRegular";   /*Can be any text*/
    src: local("Roboto-Regular"),
      url("../../fonts/Roboto-Regular.ttf") format("truetype");
  }
  
  .App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    color: white;
    text-align: center;
    font-family: "RobotoRegular";
    align-self: center;
    overflow: visible;
    background-size: var(--background-size);
  }

  .App-size{
    display: none;
    flex-direction: column;
    margin: auto;
  }

  .App-size.lg{
    display: flex;
  }

  .App-size.sm{
    display: none;
  }

  .App-logo{
    pointer-events: none;
    width: 20%;
    margin: 1%;
  }
  
  .App-logo-zoom{
    pointer-events: none;
    width: 12%;
    margin: 1%;
  }
  
  .App-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    align-self: center;
    width: 100%;
    position: sticky;
    top: 0;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    background-image: linear-gradient(rgba(0,0,0,0.6) 60%, rgba(0,0,0,0));
    z-index:99;
  }
  
  .App-header-menu {
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-shrink: 1;
    flex-grow: 1; 
    width: 50%;
  }
  
  .App-menu-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: var(--text-size-menu);
    line-height: calc(var(--text-size-menu) * 1.1);
    padding-left: calc(var(--text-padding)/4);
    padding-right: calc(var(--text-padding)/4);
    cursor: pointer!important;
  }
  
  .App-menu-text:hover {
    color: var(--red);
    font-weight: bold;
  }
  
  .App-header-burger {
    display: none;
    flex-direction: column;
    justify-self: flex-end;
    align-items: flex-end;
    align-self: center;
    position: static;
    flex-shrink: 0;
    flex-grow: 0;
    padding-right: 2%;
    padding-left: -2%;
    width: calc(20px + 2vw);
    height: calc(20px + 2vw);
    margin: var(--margin)
  }
  
  .App-header-burger-icon {
    object-fit: contain;
    border-style: none;
    cursor: pointer;
    width: 100%; 
    height: 100%;
    object-fit: contain;
  }
  
  .Small-menu {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    /* additional style */
    background: var(--red);
    color: white;
    /*padding: calc(var(--text-padding));*/
    z-index:100;
    transition: height 0.5s;
  }
  
  .Small-menu-text-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    flex-grow: 1;
    width: 100%;
  }

  .Small-menu-text{
    font-size: calc(var(--text-size) * 1.25);/*calc(10px + 1.5vw);*/
    padding: var(--text-padding);
    cursor: pointer;
  }
  
  .Small-menu-text:hover{
    color: black;
  }
  
  .Small-menu-close{
    align-self: flex-end;
    font-size: calc(var(--text-size) * 1.5);
    padding-top: calc(var(--text-padding));
    padding-right: calc(var(--text-padding) * 2);
    text-align: right;
    width:  auto;
    cursor: pointer;
    position: sticky;
    top: 0;
  }
  
  .Small-menu-close:hover{
    color: black;
  }
  
  .App-intro{
    margin-top: calc(var(--gap) * 2.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start
  }
  
  .App-technology{
    margin-top: var(--gap);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .App-tech-desc{
    margin-top: var(--margin);
    margin-bottom: calc(var(--gap)/2);
    width: calc(var(--text-size) * 32.5);/*calc(var(--base-size) * 32.5);*/
    position: relative;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .Tech-img{
    display: flex;
    object-fit: contain;
    height: calc(var(--text-size) * 26.5);/*calc(var(--base-size) * 26.75);*/
  }

  .App-reps{
    margin-top: calc(var(--gap)*1);
  }

  .App-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(var(--text-size) * 11); /*calc(var(--base-size) * 10 + .75vw);*//*calc(9px + 15vw);*/
    height: calc(var(--text-size) * 2.75); /*calc(var(--base-size) * 2 + .75vw);*//*calc(9px + 4vw);*/
    border-radius: var(--border-radius); /*1pc;*/
    border-width: var(--border-width);
    border-color: var(--red);
    border-style: solid;
    align-self:center;
    text-align: center;
    cursor: pointer!important;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: transparent;
    color: white;
    /*font-size: calc(10px + 0.5vmin);*/ /*clamp(.5rem, 5vmin, 3rem);*/
    margin-top: var(--margin);
    margin-bottom: var(--margin);
    padding: calc(var(--text-padding)*.25);
  }
  
  .App-button:hover{
    background-color: var(--red);
    /*box-shadow: inset 0px 0px 4px 4px #ffffff3a;
    border-style: none;*/
  }
  
  /*.App-button:hover:active{
    background-color: var(--red);
    box-shadow: inset 0px 0px 4px 4px #0000005b;
    border-style: none;
  }*/

  .App-footer{
    margin-top: calc(var(--gap)/2);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    background-color: var(--red);
    height: calc(10px + 3vw);
    color: black;
    font-size: calc(var(--text-size-small));
  }
  
  form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60vw;
  }
  
  input, textarea, select{
    display: flex;
    font-size: calc(var(--text-size));
    line-height: calc(var(--text-size) * 1.5);
    margin-top: calc(6px + 0.5vw);
    padding-top: calc(var(--text-size) * .25);
    padding-bottom: calc(var(--text-size) * .25);
    padding-left: calc(var(--text-size) * .5);
    padding-right: calc(var(--text-size) * .5);
    width: 100%;
    border: none;
    border-radius: calc(var(--border-radius) / 3);
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: var(--gray);
  }
  
  select{
    padding: calc(var(--text-size) * .5);
  }
  
  textarea{
    color:white;
    font-family: "RobotoRegular";
    padding-top: calc(var(--text-size) * .25);
    padding-bottom: calc(var(--text-size) * .25);
    padding-left: calc(var(--text-size) * .5);
    padding-right: calc(var(--text-size) * .5);
  }
  
  option{
    color: white;
    padding: calc(var(--text-size) * .25);
  }
  
  ::placeholder {
    color: var(--gray-text) ;
    opacity: 1; /* Firefox */
    padding: calc(var(--text-size) * .25);
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: var(--gray-text);
    padding: calc(var(--text-size) * .25);
  }
  
  div{
    font-size: var(--text-size); /*calc(7px + 1vw)*/
  }
  
  h1{
    color: var(--red);
    font-family: "trajan-sans-pro",sans-serif;
    font-weight: 700;
    font-size: var(--text-size-h1); /*(8px + 5vw);*/
    line-height: calc(var(--text-size-h1)*1.2);
  }

  h2, h3{
    color: var(--red);
    font-family: "trajan-sans-pro",sans-serif;
    margin: 0px;
  }
  
  h2{
    font-size: var(--text-size-h2); /*calc(8px + 1.75vw);*/
    line-height: calc(var(--text-size-h2)*1.2); /*calc(16px + 1.75vw);*/
  }

  h3{
    font-size: var(--text-size-h3);
    line-height: calc(var(--text-size-h3)*1.2);
    text-align: center;
  }

@media (max-width: 775px) {
  .App-size.lg{
    display: none;
  }

  .App-size.sm{
    display: flex;
  }

  .App-menu-text{
    display: none; 
  }

  .App-logo{
    width: 40%;
  }

  .App-logo-zoom{
    width: 25%;
  }

  .App-header-burger{
    display: flex;
  }

  .App-button{
    /*
    width: calc(var(--base-size) * 8 + 1vw);
    height: calc(var(--base-size) * 1.8 + 1vw);
    */
    margin-top: calc(var(--margin));
    margin-bottom: calc(var(--margin));
  }

  .App-intro{
    margin-top: calc(var(--gap) * 2);
  }

  .App-tech-desc{
    width: 80vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .Tech-img{
    display: flex;
    object-fit: contain;
    height: auto;
    width: 75vw;
    margin-left: auto;
    margin-right: auto;;
  }

  .App-reps{
    margin-top: calc(var(--gap)*2);
  }

  .App-footer{
    flex-direction: column;
    font-size: calc(var(--text-size-small) * .75);
    padding-bottom: calc(var(--margin));
    height:fit-content;
  }

  .App-footer-phone{
    font-size: calc(var(--text-size) * 1.25);
    line-height: calc(var(--text-size) * 1.25 *1.2);
    margin-top: calc(var(--margin));
    margin-bottom: calc(var(--text-size) * -.5);
  }

  h1{
    font-weight: 700;
    font-size: var(--text-size-h1); /*(8px + 5vw);*/
    line-height: calc(var(--text-size-h1)*1.2);
    width: 80vw;
    margin:auto;
  }

  h2,h3{
    width: 90vw;
  }

  h2{
    font-size: var(--text-size-h2); /*calc(8px + 1.75vw);*/
    line-height: calc(var(--text-size-h2)*1.2); /*calc(16px + 1.75vw);*/
  }

  h3{
    display: flex;
    font-size: var(--text-size-h3);
    line-height: calc(var(--text-size-h3)*1.2);
    text-align: center;
    width: 70vw;
  }

  form{
    width: 85vw;
  }

}

@media (max-width: 500px) {
  .App-intro{
    margin-top: calc(var(--gap) * 2.5);
  }
}