.Acc{
    margin-top: calc(var(--gap));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    background-color: var(--red);
    border-radius: var(--border-radius);
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    flex-grow: 0;
    width: 80vw; /*80%;*/
    min-height: calc(var(--base-size) * 13.5);/*calc(8px + 20vw);*/
    padding-bottom: calc(6px + 1vmin);
  }
  
  .Acc-title{
    font-family: "trajan-sans-pro",sans-serif;
    color: var(--black);
    /*font-size: calc(8px + 4vw);*/
    font-size: calc(var(--text-size) * 1.4);/*clamp(calc(1em + 0.5vw),5vw,calc(10em + 0.5vw));*/
    font-weight: bold;
    margin: var(--margin);
  }
  
  .Acc-desc{
    width: 90%;
    font-size: var(--text-size-small); /*clamp(calc(.55em + 1vw),0.5vw,calc(.7em + 1vw));*/
    line-height: calc(var(--text-size-small) * 1.2);
    margin-bottom: var(--margin);
    margin-top: calc(-1.25 * var(--margin));
    padding: var(--text-padding);
  }
  
  .Acc-pics{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-self: center;
    margin-top: calc(-6px - 14vw);
    position: relative;
    width: 80vw;
   }
  
  .Acc-pic{
    object-fit: contain;
  }
  
@media(max-width: 775px){
  .Acc-pics{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-self: center;
    align-self: center;
    margin-top: calc(var(--text-size) * 2);
    margin-bottom: calc(var(--text-size) * -6);
    position: relative;
    width: 80vw;
    }
  .Acc-pics-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: calc(var(--text-size) * -4);
  }

  @media only screen and (orientation: landscape) {
    .Acc-pics{
      margin-bottom: calc(var(--text-size) * -9.25);
    }
  }
}