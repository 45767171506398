.Nomad-margin{
    margin-top: calc(var(--gap)); /*10pc;*/
}
  
.Nomad-details-margin{
  margin-left: clamp(0, 1vw, calc(5px + 1vw)); /*0*/;
  margin-top: calc(-12px - 17.5vw);
}

.Nomad-margin-text{
    margin-top: calc(6px + 15vw);
    padding-left: clamp(calc(4px + 0.5vw), 20vw, calc(6px + 17.5vw)); /*calc(6px + 17.5vw);*/
    padding-right: clamp(calc(-6px - 0.75vw), 1vw, calc(6px + 2vw)); /*calc(6px + 2vw);*/
}

.Nomad-image{
  width: 35%; /*calc(24px + 26vw);*/
  /*object-fit: contain;*/
  padding-left: 0;
  margin-left: clamp(0, 1vw, calc(5px + 1vw)); /*0;*/ /*calc(-10px - 15vw);*/
  margin-right: clamp(calc(-10px + 0.5vw), 1vw, calc(-10px - 7.5vw)); /*0; */
  flex-grow: 0;
  flex-shrink: 0;
}

@media(max-width: 775px){
  .Nomad-margin{
    margin-top: calc(var(--gap)/2); /*10pc;*/
  }

  .Nomad-details-margin{
    margin-left: 0; /*0*/;
    margin-top: 0;
  }

  .Nomad-margin-text{
      margin-top: 0;
      padding-left: 0; /*calc(6px + 17.5vw);*/
      padding-right: 0; /*calc(6px + 2vw);*/
  }

  .Nomad-image{
    width: 70%; /*calc(24px + 26vw);*/
    /*object-fit: contain;*/
    padding-left: 0;
    margin-left: 0; /*0;*/ /*calc(-10px - 15vw);*/
    margin-bottom: calc(var(--gap) * -1.65);
    margin-right: 0; /*0; */
    flex-grow: 0;
    flex-shrink: 0;
  }

  @media only screen and (orientation: landscape) {
    .Nomad-image{
      margin-bottom: calc(var(--gap) * -2.5);
    }
  }
}
