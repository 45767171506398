.Odin-margin{
  margin-top: calc(var(--gap) * 1); /*0*/ /*10pc;*/
}
  
.Odin-details-margin{
  margin-left: calc(-12px - 5vw);
  margin-top: calc(-12px - 9vw);
}

.Odin-margin-text{
    margin-top: calc(6px + 7.5vw);
    /*padding-left: calc(6px + 17.5vw);
    padding-right: calc(6px + 4vw);*/
    padding-left: clamp(calc(4px + 0.5vw), 20vw, calc(6px + 17.5vw));
    padding-right: clamp(calc(-6px - 0.75vw), 1vw, calc(6px + 2vw));
}

.Odin-image{
  width: 27.5vw; /*calc(24px + 26vw);*/
  /*object-fit: contain;*/
 /* padding-left: 0;
  margin-left: 0;*/
  margin-top: calc(12px + 1vw);
  margin-right: clamp(calc(-12px + 0.75vw), -1.5vw, calc(6px + 1vw));
 /* flex-grow: 0;
  flex-shrink: 0;
  */
}

@media only screen and (orientation: landscape) {
  .Odin-margin{
    margin-top: calc(var(--gap) * .5);
  }
}

/*
@media (max-width: 2100px) {
  .Odin-details-margin{
    margin-left: clamp(calc(12px - 5vw), 2vw, calc(12px - 5vw));
  }

  .Odin-image{
    margin-right: clamp(0px, 5vw, calc(6px + 0.5vw)); 
  }
}
*/
@media(max-width: 775px){
  .Odin-margin{
    margin-top: calc(var(--gap)); /*calc(var(--gap) / 8); /*10pc;*/
  }

  .Odin-details-margin{
    margin-left: 0; /*0*/;
    margin-top: 0;
  }

  .Odin-margin-text{
      margin-top: 0;
      padding-left: 0; /*calc(6px + 17.5vw);*/
      padding-right: 0; /*calc(6px + 2vw);*/
  }

  .Odin-image{
    width: 70%; /*calc(24px + 26vw);*/
    /*object-fit: contain;*/
    padding-left: 0;
    margin-left: 0; /*0;*/ /*calc(-10px - 15vw);*/
    margin-right: 0; /*0; */
    margin-bottom: calc(var(--gap) * -1.7);
    flex-grow: 0;
    flex-shrink: 0;
  }
  
  @media only screen and (orientation: landscape) {
    .Odin-image{
      margin-bottom: calc(var(--gap) * -2.85);
    }
  }
}
