.Inno{
    margin-top: calc(var(--gap));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    flex-grow: 0;
    width: 80vw; /*80%;*/
    height: calc(8px + 27vw);
    background-size: contain;
  }
  
  .Inno-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    width: 80vw;
    text-align: left;
    padding-bottom: var(--text-padding);
    padding-top: var(--text-padding);
  }
  
  .Inno-title{
    color: var(--red);
    font-family: "trajan-sans-pro",sans-serif;
    font-size: calc(var(--text-size) * 1.4); /*clamp(calc(.55em + 1vw),0.5vw,calc(.7em + 1vw));*/
    line-height: calc(var(--text-size) * 1.4 * 1.2); 
    font-weight: bold;
    padding-left: 32.5%;
    width: 62.5%;
    align-self: flex-start;
  }
  
  .Inno-desc{
    margin-top: calc(var(--margin) / 2);
    align-self: flex-start;
    padding-left: 32.5%;
    width: 62.5%;
    color: var(--black);
    font-size: calc(var(--text-size-small)); /*clamp(calc(.1em + 1vw),0.25vw,calc(.7em + 1vw));*/
    line-height: calc(var(--text-size-small));
  }

@media(max-width: 775px){
  .Inno{
    width: 90vw; /*80%;*/
    background-size: cover;
    height: fit-content;
  }

  .Inno-title{
    text-align: center;
    font-size: calc(var(--text-size) * 1.5);
    line-height: calc(var(--text-size) * 1.5 * 1.2);
    padding: var(--text-padding);
    width: 90%;
  }
  
  .Inno-desc{
    text-align: center;
    padding: var(--text-padding);
    width: 90%;
    color: var(--black);
    font-size: calc(var(--text-size)); /*clamp(calc(.1em + 1vw),0.25vw,calc(.7em + 1vw));*/
    line-height: calc(var(--text-size) * 1.2);
    padding-top: 0;
    margin-bottom: var(--margin);
  }

  @media only screen and (orientation: landscape) {
    .Inno-title{
      font-size: calc(var(--text-size) * 2.5);
      line-height: calc(var(--text-size) * 2.5 * 1.2);
    }
  }
}