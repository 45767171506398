.Checkbox-row{
    margin-top: calc(6px + 0.5vw);
    line-height: calc(var(--text-size) * 1.5);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }
  
  .Checkbox-row.text{
    font-size: var(--text-size);
    margin-left: var(--margin);
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .checkbox-wrapper-39 *,
  .checkbox-wrapper-39 *::before,
  .checkbox-wrapper-39 *::after {
    box-sizing: border-box;
  }
  
  .checkbox-wrapper-39 label {
    display: block;
    width: calc(10px + 2vw); /*35px;*/
    height: calc(10px + 2vw); /*35px;*/
    cursor: pointer;
  }
  
  .checkbox-wrapper-39 input {
    visibility: hidden;
    display: none;
  }
  
  .checkbox-wrapper-39 input:checked ~ .checkbox {
    transform: rotate(45deg);
    width: calc(6px + .5vw);
    margin-left: calc(6px + .75vw);
    border-color: #24c78e;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 0;
  }
  
  .checkbox-wrapper-39 .checkbox {
    display: block;
    width: inherit;
    height: inherit;
    border: var(--border-width) solid var(--gray);
    border-radius: calc(var(--border-radius) / 4);
    transition: all 0.375s;
  }
  