.Product{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: fit-content;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  
  .Product-name{
    color: var(--red);
    font-family: "trajan-sans-pro",sans-serif;
    font-weight: bold;
    font-size: calc(8px + 11.75vw);/*15pc;*/
    text-align: center;
  }
  
  .Product-details{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    /*width: 60pc;*/
    width: 100%; /*calc(24px + 60vw);*/
    /*margin-left: 10pc;*/
    height:fit-content;
    margin-left: auto;
    margin-right: auto;
   }
  
  .Product-text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    width: auto; /*calc(24px + 60vw);*/
    margin-left: 0pc;
    text-align: left;
    flex-grow: 1;
    flex-shrink: 1;
    padding-bottom: calc(6px + 1vw);
   }
  
  .Product-title{
    margin-top: var(--margin);
    font-size: var(--text-size); /*calc(8px + 1.1vw)*/;/*calc(20px + 2vmin)*/;
    font-weight: bold;
    /*width: 35pc;*/
  }
  
  .Product-desc{
    font-size: var(--text-size); /*calc(8px + 0.6vw);*/
    margin-top: var(--margin);
    margin-bottom: var(--margin);
    width: 100%;
  }

@media(max-width: 775px){
  .Product-name{
    color: var(--red);
    font-family: "trajan-sans-pro",sans-serif;
    font-weight: bold;
    font-size: calc(8px + 15vw);/*15pc;*/
    text-align: center;
  }

  .Product-title{
    margin-top: 0;/*calc(var(--margin));*/
    font-size: var(--text-size); /*calc(8px + 1.1vw)*/;/*calc(20px + 2vmin)*/;
    width: 70vw;
  }

  .Product-desc{
    margin-top: var(--text-size);
    width: 65vw;
    margin-bottom: 0;
  }
}