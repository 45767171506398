
.Ragnar-margin{
    margin-top: calc(var(--gap) * 1.0); /*0;*/ /*10pc;*/
    /*margin-bottom: calc(var(--gap) * -.5);*/
}
  
.Ragnar-details-margin{
  margin-left: clamp(calc(-12px + 2vw), 7.5vw, calc(6px + 5.75vw));
  margin-top: calc(-12px - 13vw);
}

.Ragnar-margin-text{
    margin-top: calc(6px + 11vw);
    padding-left: calc(6px + 6vw);
    padding-right: clamp(calc(6px + 1vw), 20vw, calc(6px + 15vw)); /*calc(6px + 15vw);*/
}

.Ragnar-image{
  width: 27.5vw; /*calc(24px + 26vw);*/
  /*object-fit: contain;*/
  /*padding-right: 0; /*clamp(0, 1vw, calc(5px + 5vw));*/
 /* margin-left: clamp(0, 1vw, calc(5px + 1vw));*/
  /*margin-right: clamp(0, 1vw, calc(-10px - 7.5vw));*/
  }
 
  @media only screen and (orientation: landscape) {
    .Ragnar-margin{
      margin-top: calc(var(--gap) * 1.5); /*0;*/ /*10pc;*/
      /*margin-bottom: calc(var(--gap) * -.5);*/
    }

    .Ragnar-details-margin{
      margin-left: clamp(calc(-12px + 2vw), 7.5vw, calc(6px + 6.5vw));
    }
  }
  /*
  @media (max-width: 1200px){
    .Ragnar-details-margin{
      margin-left: clamp(calc(-6px + 2vw), 7.5vw, calc(6px + 5.5vw));
    }
  }
*/
  @media(max-width: 775px){
    .Ragnar-margin{
      margin-top: var(--gap); /*calc(var(--gap) / 8); /*10pc;*/
    }
  
    .Ragnar-details-margin{
      margin-left: 0; /*0*/;
      margin-top: 0;
    }
  
    .Ragnar-margin-text{
        margin-top: 0;
        padding-left: 0; /*calc(6px + 17.5vw);*/
        padding-right: 0; /*calc(6px + 2vw);*/
    }
  
    .Ragnar-image{
      width: 70%; /*calc(24px + 26vw);*/
      /*object-fit: contain;*/
      padding-left: 0;
      margin-left: 0; /*0;*/ /*calc(-10px - 15vw);*/
      margin-right: 0; /*0; */
      margin-bottom: calc(var(--gap) * -2.9);
      flex-grow: 0;
      flex-shrink: 0;
    }

    @media only screen and (orientation: landscape) {
      .Ragnar-image{
        margin-bottom: calc(var(--gap) * -4.6);
      }
    }
  }

