  .App-icons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: calc(var(--base-size) * 2.5);;
    margin-bottom: var(--gap);
    height: 100%;
  }
  
  .App-icon{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(var(--base-size) * 12);
    margin-left: calc(var(--margin)/8);
    margin-right: calc(var(--margin)/8);
    font-size: var(--text-size-small);/*calc(8px + .65vw);*/
    overflow: visible;
    height: calc(var(--base-size) * 12);
    flex-grow: 1;
    flex-shrink: 1;
    /*margin-bottom: calc(var(--margin)/8);*/
    position: relative;
  }
  
  .App-icon-size{
    width: calc(var(--base-size) * 4);
    height: calc(var(--base-size) * 4);
    border-color: var(--red);
    border-style: solid;
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    margin: var(--margin);
    margin-bottom: calc(var(--margin) / 2);
  }

@media(max-width: 775px){
  .App-icons{
    margin-top: calc(var(--gap)/2);
    align-items: center;
    margin-bottom: calc(var(--gap)/2);
  }

  .App-icon{
    width: 55vw;
    margin-left: calc(var(--margin)/8);
    margin-right: calc(var(--margin)/8);
    font-size: calc(var(--text-size));/*calc(8px + .65vw);*/
    overflow: hidden;
    margin-bottom: calc(var(--margin));
    height: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }
  
  .App-icon-size{
    width: calc(var(--base-size) * 3);
    height: calc(var(--base-size) * 3);
    border-radius: calc(var(--border-radius));
    border-width: calc(var(--border-width));
    margin: calc(var(--margin));
  }
}