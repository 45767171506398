:root{
    --red: #ac1a1f;
    --black: #000000;
    --green: #13ce4b;
    --gray: #353535;
    --gray-text: #979393;
    --white: #ffffff;
    --base-size: calc(.75rem + 0.75vw); /*calc(.525rem + 0.75vw);*/
    --text-size: clamp(var(--base-size) * 1, 1vw, var(--base-size) * 1.5); /*clamp(calc(.6rem + 0.75vw),1vw,calc(.9rem + 1vw));*/
    --text-size-small: calc(var(--text-size) * .9);
    --text-size-h1: calc(var(--text-size) * 2.5);
    --text-size-h2: calc(var(--text-size) * 1.5);
    --text-size-h3: calc(var(--text-size) * 1.25);
    --text-size-menu: calc(var(--text-size) * .75);
    --text-padding: var(--text-size);
    --border-width: calc(var(--text-size) * .15);/*calc(var(--base-size) / 8);*/
    --border-radius:  calc(var(--text-size) * .5); /*calc(var(--base-size) / 2);*/
    --margin: calc(var(--text-size) * 1.5);
    --gap: calc(var(--base-size) * 6 + .75vw);/*calc(var(--text-size) * 8.5);*/
    --background-size: calc(var(--text-size) * 72.5 + 10vw); /*calc(var(--base-size) * 50 + 25vw);*/

}

@media(max-width: 775px){
    :root{
        --base-size: calc(1.25rem + .75vw);
        --text-size: clamp(var(--base-size) * .75, 1vw, var(--base-size) * 1.5);
        --text-size-small: calc(var(--text-size) * .9);
        --text-size-h1: calc(var(--text-size) * 2.5);
        --text-size-h2: calc(var(--text-size) * 1.5);
        --text-size-h3: calc(var(--text-size) * 1.5);
        --text-size-menu: calc(var(--text-size) * .75);
        --text-padding: var(--text-size);
        /*--border-width: calc(var(--base-size) / 8);
        --border-radius:  calc(var(--base-size) / 2);
        --margin: calc(var(--text-size) * 1.5);*/
        --gap: calc(var(--base-size) * 2.0 );/*calc(var(--text-size) * 3.5);*/ 
        --background-size: calc(var(--text-size) * 67.5 + 5vw); /* calc(var(--base-size) * 50 + 25vw);*/
    } 
}
